.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #444444;
  color: white;
  padding: 1rem;
}

.navbar-logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1.5rem;
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.navbar-links li {
  margin-left: 1rem;
}

.navbar-links a,
.navbar-links button,
.navbar-links span.disabled,
.menu-toggle {
  color: white;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s, opacity 0.3s;
  display: inline-block;
  line-height: 1.5;
}

.navbar-links .active-link.disabled {
  background-color: #373737;
  opacity: 0.5;
  cursor: default;
}

.update-btn {
  background-color: #373737;
}

.logout-btn {
  background-color: #e74c3c;
  margin-right: 2rem;
}

.menu-toggle {
  display: none;
  font-size: 1.5rem;
  padding: 0.3rem 0.8rem;
}

/* Nuevos estilos para el enfoque */
.menu-toggle:focus,
.navbar-links a:focus,
.navbar-links button:focus {
  outline: none;
  background-color: #555555;
}

@media (max-width: 768px) {
  .navbar {
    flex-wrap: wrap;
  }

  .menu-toggle {
    display: block;
    /* margin-right: 1.5rem; */
  }

  .navbar-links {
    display: none;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-links li {
    margin: 0.5rem 0;
    width: 100%;
  }

  .navbar-links a,
  .navbar-links button,
  .navbar-links span.disabled {
    display: block;
    width: 100%;
    text-align: left;
  }

  .logout-btn {
    margin-right: 0;
  }
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo-image {
  height: 40px;
  /* margin-left: 5px; */
}

.navbar-logo a {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .navbar-logo-image {
    height: 30px;
  }
}