.accordion-enter {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
  .accordion-enter-active {
    max-height: 1000px;
    opacity: 1;
    transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  }
  .accordion-exit {
    max-height: 1000px;
    opacity: 1;
  }
  .accordion-exit-active {
    max-height: 0;
    opacity: 0;
    transition: max-height 500ms ease-in-out, opacity 500ms ease-in-out;
  }
  