.bar {
  background-color: #4a90e2;
  border-radius: 4px 4px 0 0;
  bottom: -25px;
  color: #e0e0e0;
  font-size: 12px;
  left: 50%;
  position: absolute;
  transition: height 0.3s ease;
  width: 40px;
}

.bar span {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: #e0e0e0;
}

.card {
  background-color: #1e1e1e;
  border: none;
  color: lightgray !important;
}

.card *,
.card-body * {
  color: lightgray !important;
}

.card-body {
  background-color: #1e1e1e;
  color: lightgray !important;
}

.card-text {
  color: lightgray !important;
}

.card-title {
  color: lightgray !important;
}

.chart-container {
  align-items: flex-end;
  display: flex;
  height: 200px;
  justify-content: space-around;
  margin-top: 20px;
}

.custom-dropdown {
  background-color: #0056b3 !important;
  color: #ffffff !important;
  border: 1px solid #0056b3 !important;
}

.custom-button {
  background-color: #004085 !important;
  border-color: #004085 !important;
  color: lightgray !important;
  white-space: nowrap;
}

.custom-button:hover {
  background-color: #003366 !important;
  border-color: #00274d !important;
}

.custom-button-hide {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  color: #ffffff !important;
}

.custom-button-hide:hover {
  background-color: #5a6268 !important;
  border-color: #545b62 !important;
}

.dashboard-container {
  background-color: #121212;
  color: #e0e0e0;
  font-family: Arial, sans-serif;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0px;
}

.dashboard-container-dispositivos .card-body {
  background-color: #2c2c2c;
  color: lightgray !important;
  background-color: inherit;
  color: inherit;
}

.dashboard-container-dispositivos-summary .card-body {
  background-color: #2c2c2c;
  color: lightgray !important;
  background-color: inherit;
  color: inherit;
}

.dashboard-content {
  background-color: #1e1e1e;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color: lightgray;
  padding: 20px;
}

.dashboard-header {
  background-color: #333333;
  border-radius: 8px 8px 0 0;
  color: #ffffff;
  padding: 20px;
}

.dashboard-header-alert {
  background-color: #9c8500;
  border-radius: 4px 4px 4px 4px;
  color: lightgray;
  padding: 5px;
}

.dashboard-header-alert h6 {
  margin: 0;
}

.shadowcard {
  background-color: #1e1e1e;
  border-radius: 8px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, .6);
  margin-bottom: 10px;
  margin-top: 30px;
}

.small-text {
  font-size: 11px;
}

.stats-section {
  margin-top: 30px;
}

.welcome-section {
  margin-bottom: 30px;
}

/* DetailCharts hr  */

.detail-hr{
  margin: 0.3rem 0;
}

/* Cards DetailChart */
.detail-card-cardbody{
  padding: 0.4rem;
  font-size: 0.8rem;
  background-color: #333333;
  color: lightgrey;
}

.detail-card-section{
  margin-bottom: 0.2rem;
}

.detail-card-title{
  font-size: 0.9rem;
  margin-bottom: 0.1rem;
  font-weight: bold
}

.detail-card-subtitle{
  font-size: 0.8rem;
}

.custom-font-size-8 {
  font-size: 0.8rem;
}

.main-panel-icon {
  margin-left: 0.1rem;
  margin-right: 0.5rem;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  color: #d4d4d4;
  font-size: 1rem;
}

.footer p {
  margin: 0;
}
