/* Área de Tables */
.starlink-table {
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 12px;
    background-color: #2c2c2c;
    color: lightgrey;
    margin-bottom: 10px;
  }
  
  .dashboard-card-dispositivos {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .dashboard-card-body-dispositivos {
    background: #2c2c2c;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: none;
  }
  
  .dashboard-row-dispositivos {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .dashboard-title-dispositivos {
    color: lightgrey;
  }
  
  /* Cards */
  
  .dashboard-container-dispositivos {
    background: #2c2c2c;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 80vh;
    width: 95%;
    margin: 0 auto;
  }

  .dashboard-container-dispositivos-summary {
    background: #2c2c2c;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
  }
  
  .card-dispositivos{
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .card-body-dispositivos {
    background: #2c2c2c;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: none;
  }
  
  .row-dispositivos {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .col-dispositivos {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .col-dispositivos h2 {
    color: lightgrey;
  }
  