.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
  }
  
  .loading-content {
    text-align: center;
  }
  
  .loading-spinner {
    width: 80px;
    height: 80px;
    border: 5px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: lightgray;
    animation: spin 1s ease-in-out infinite;
  }
  
  .loading-text {
    color: lightgray;
    font-size: 2.5rem;
    font-weight: 700;
    margin-top: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    opacity: 0;
    animation: fadeInText 0.5s ease-in-out 0.5s forwards;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeInText {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }