.modal-content {
  position: relative;
  background-color: #1e1e1e !important;
  border-radius: 5px;
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  color: lightgray!important;
}

.custom-input {
  /* background-color: #444444!important;
  color: lightgray!important;
  border: 1px solid #4d4d4d!important; */
  background-color: #a1a1a1!important;
  border: 1px solid #a1a1a1!important;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.custom-input::placeholder {
  color: lightgray;
}

.custom-input-disabled {
  background-color: #1e1e1e!important;
  color: #a9a9a9!important;
  border: 1px solid #444444!important;
  cursor: not-allowed;
  opacity: 0.5;
}

.custom-input-disabled::placeholder {
  color: #a9a9a9;
}

.custom-button-save {
  background-color: #0053ab !important;
  border-color: #0053ab !important;
  color: lightgray !important;
  white-space: nowrap;
}

.custom-button-save:hover {
  background-color: #003366 !important;
  border-color: #00274d !important;
}

.custom-modal {
  max-width: 600px;
  width: 90%;
  margin: 1.75rem auto;
}

.custom-modal .modal-content {
  border-radius: 0.3rem;
}

.custom-modal .modal-header {
  border-bottom: 1px solid lightgray;
  padding: 1rem;
}

.custom-modal .modal-body {
  padding: 1rem;
}

.custom-modal .modal-footer {
  border-top: 1px solid lightgray;
  padding: 1rem;
}

.custom-input {
  margin-bottom: 1rem;
}