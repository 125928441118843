body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; /* Oculta el scroll horizontal pero permite el vertical */
  overflow-y: auto;   /* Asegura que el scroll vertical esté habilitado */
}

.login-container {
  display: flex;
  height: 100vh;
}

.background-image {
  flex: 2;
  background-image: url('../logos/fondo-login1174.jpg');
  background-size: cover;
  background-position: center;
}

.login-form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #05080e;
  padding: 2rem;
  box-shadow: -10px 0 20px rgba(0, 0, 0, 0.2);
}

.login-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

h2 {
  text-align: center;
  color: #d4d4d4;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.input-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #d4d4d4;
  font-size: 1rem;
}

input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #3c3c3c;
  border-radius: 4px;
  font-size: 1rem;
  background-color: #2d2d2d;
  color: #d4d4d4;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus {
  outline: none;
  border-color: #007acc;
  box-shadow: 0 0 0 2px rgba(0, 122, 204, 0.2);
}

button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007acc;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #005a9e;
}

button:disabled {
  background-color: #4a4a4a;
  cursor: not-allowed;
}

.error-message {
  color: #f44336;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.success-message {
  color: #4caf50;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .background-image {
    flex: 1;
    min-height: 200px;
  }

  @media (max-width: 768px) {
    .background-image {
      display: none;
    }
  }

  .login-form-container {
    flex: 2;
  }
}